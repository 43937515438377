<script setup lang="ts">
import type { Promotion } from "@/types";

const props = defineProps<{
	type?: Promotion["type"];
	image?: string;
	entries?: number;
	coins?: number;
	dateLeft?: string;
	dateStart?: string;
	loading?: boolean;
	isSub?: boolean;
	isSweepStakes?: boolean;
	isActive?: boolean;
	title?: string;
}>();

const { t } = useT();
const { open } = useTaoModals();

const emit = defineEmits<{ (event: "subscribe"): void; (event: "moreInfo"): void }>();

const handleClick = () => {
	if (!props.isActive) {
		emit("moreInfo");
		return;
	}
	emit("subscribe");
};

const textBtn = computed(() => {
	if (!props.isActive) {
		return t("join now");
	}
	return props.isSub ? t("Play now") : t("join now");
});

const { durationLeft: dateStartLeft } = useCountdown({
	timestamp: props.dateStart || "",
	formatToken: "HH[ H ]mm[ M ]ss[ S ]"
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 105,
			height: 85,
			src: image,
			alt: 'tournament card',
			format: 'avif',
			loading: 'lazy'
		}"
		width="328px"
		height="325px"
		:class="['card-tournament', { 'no-active': !isActive }]"
		@click="handleClick"
	>
		<template #top>
			<div :class="['badge', { sc: isSweepStakes }]">
				<AText :size="18" :class="isSweepStakes ? 'text-chizhou' : 'text-chofu'" :modifiers="['bold', 'uppercase']">
					{{ isSweepStakes ? t("Sc") : t("Tc") }}
				</AText>
				<AText :class="isSweepStakes ? 'text-chizhou' : 'text-chofu'" :modifiers="['uppercase']">
					{{ t("Mode") }}
				</AText>
			</div>

			<AText class="label-time" :size="12">
				<i18n-t v-if="isActive" keypath="Time left {key}">
					<template #key>
						<b>{{ dateLeft }}</b>
					</template>
				</i18n-t>
				<i18n-t v-else keypath="Start in {key}">
					<template #key>
						<b>{{ dateStartLeft }}</b>
					</template>
				</i18n-t>
			</AText>

			<AText class="title" :modifiers="['bold', 'uppercase']">{{ title }}</AText>
		</template>
		<template #bottom>
			<template v-if="isSweepStakes">
				<MPrizeFund v-if="entries" icon="12/secret-coins" variant="entries" :iconSize="24">
					<AText :size="28" :modifiers="['bold']">
						{{ numberFormat(entries) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund v-if="coins" icon="12/coins" variant="coins" :iconSize="20">
					<AText class="color-neutral" :size="16" :modifiers="['bold']">
						{{ numberFormat(coins) }}
					</AText>
					<AText type="xx-small" class="color-neutral" :modifiers="['uppercase']">
						{{ t("Free Tao Coins") }}
					</AText>
				</MPrizeFund>
			</template>
			<template v-else>
				<MPrizeFund v-if="coins" icon="12/coins" variant="coins" :iconSize="24">
					<AText class="color-neutral" :size="28" :modifiers="['bold']">
						{{ numberFormat(coins) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund v-if="entries" icon="12/secret-coins" variant="entries" :iconSize="20">
					<AText :size="16" :modifiers="['bold']">
						{{ numberFormat(entries) }}
					</AText>
					<AText type="xx-small" class="color-neutral" :modifiers="['uppercase']">
						{{ t("Free Secret Coins") }}
					</AText>
				</MPrizeFund>
			</template>

			<div v-if="!(entries && coins)" class="intro">
				<AText class="sub-title" :size="12" :modifiers="['uppercase']">
					{{ isSweepStakes ? t("Win Secret Coins by free play") : t("Win TAO Coins by free play") }}
				</AText>
			</div>

			<div class="actions">
				<AButton
					size="s"
					:modifiers="['wide', !isActive ? 'disabled' : '']"
					variant="primary"
					data-tid="promo-tournament-go"
					:disabled="!isActive"
				>
					<AAnimationSpin v-if="loading">
						<NuxtIcon class="preloader-icon" name="24/loader" filled />
					</AAnimationSpin>
					<AText v-else :modifiers="['uppercase', 'bold']">{{ textBtn }}</AText>
				</AButton>

				<AText
					as="div"
					class="foot"
					:size="12"
					:modifiers="['bold', 'uppercase', 'underline']"
					@click.stop="open('LazyOModalTournamentHowItWorks')"
				>
					{{ t("How it works") }}
				</AText>
			</div>
		</template>
	</ACard>
</template>

<style lang="scss" scoped>
.card {
	background: var(--gradient-1);

	&.no-active {
		background: linear-gradient(180deg, #5e3182 0%, #1b0041 100%);
	}
}

.badge {
	border-radius: 0px 0px 0px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px;
	background: var(--chengdu);
	line-height: 1;
	position: absolute;
	top: 0;
	right: 0;

	&.sc {
		background: var(--cabimas);
	}
}

.label-time {
	border-radius: 4px;
	background: var(--overlay-100);
	padding: 3px 4px 2px;

	b {
		text-transform: lowercase;
	}
}

.card-tournament {
	&.default:deep(> img) {
		width: 105px;
	}

	&:deep(.bottom) {
		gap: 0;
	}

	.intro {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.actions {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 12px;
	}

	&.no-active {
		&:deep(.prize) {
			opacity: 0.5;
		}

		.foot,
		.intro {
			opacity: 0.5;
		}
	}
}
</style>
